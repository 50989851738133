const Steps = ({ step = '1' }) => {
  const styleSelected =
    'text-[#DCCFBF] border-4 border-[#d1b48f] rounded-full px-[6px]';
  const styleNoSelected = 'border-4 border-transparent rounded-full px-[6px] text-white';
  return (
    <div className="h-[3.7rem] py-4 text-center flex mx-auto gap-4 text-white font-bold text-sm">
      <p className={step === 1 ? styleSelected : styleNoSelected}>1</p>
      <p className={step === 2 ? styleSelected : styleNoSelected}>2</p>
      <p className={step === 3 ? styleSelected : styleNoSelected}>3</p>
    </div>
  );
};

export default Steps;
