import { useForm } from 'react-hook-form';

import Button from '../../../common/button';

const StepOne = ({ handleStep = () => {}, stepOneData = {} }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: stepOneData,
  });

  const onSubmit = (data) => {
    handleStep(2, data);
    // console.log(errors)
  };

  const inputTextStyles =
    'block w-full text-gray rounded-md pl-2 pr-4 focus:border-[#d1b48f] focus:ring-[#d1b48f] sm:text-sm';
  const spanStyles = 'text-[#d1b48f] block text-white text-lg';
  const labelStyles = 'block pb-2';
  const errorStyles = 'block pt-2 text-[#e34462] text-sm';

  return (
    /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
    <div className="w-[95%] mx-auto grid md:grid-cols-1 md:grid-flow-col">
      {/* <div className="text-white text-center self-center">
        <h1 className="text-[#FF7C9B] hashtag py-4 text-4xl sm:text-[50px]">
          #LaCenaMasPoderosaDelAño 2023
        </h1>

        <p className="p w-11/12 mx-auto sm:text-lg">
          Una <strong>experiencia gastronómica, social y educativa</strong> para
          sentarnos en la mesa a hablar de lo que no se habla. Será en el{' '}
          <strong>restaurante Prudencia, en la Candelaria, Bogotá</strong>. Es
          cocina de autor, deliciosa y mega reconocida por su exquisita
          propuesta.
          <br />
          <br />
          El <strong>martes 1 y miércoles 2 de Noviembre 6:00pm.</strong>
          <br />
          <br />
          Vendrán desde Barú, Urabá, Cali y Cartagena viajen{' '}
          <strong>4 Poderosas</strong> por primera vez a Bogotá a contar cómo
          una
          <strong>#EducaciónPoderosa impactó su vida</strong> y porqué es
          urgente asumir esta <strong>#AsignaturaPendiente</strong> para sus
          comunidades ypara el país. Desde sus comunidades tres Poderosas y dos
          Poderosos viajarán por primera vez a Bogotá a contarnos su testimonio,
          cómo una educación poderosa transformó su vida y porqué es urgente
          asumir esta <strong>#AsignaturaPendiente</strong>.
          <br />
          <br />
          Va a ser una experiencia espectacular.
        </p>
      </div> */}

      <div className="mt-8 sm:mt-0">
        <h1 className="text-[#d1b48f]  text-center font-bold text-2xl">
          Eres importante para nosotrxs
        </h1>
        <p className="text-white text-center sm:text-lg pb-2">
          Y queremos mantenernos en contacto, por eso necesitamos que llenes
          este breve formulario.
        </p>
        <form
          className="sm:grid sm:gap-2 w-11/12 sm:w-full mx-auto"
          // className="grid gap-2 grid-cols-1 sm:grid-cols-2"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* name - Nombre y apellido */}
          <label className={labelStyles}>
            <span className={spanStyles}>Nombre y apellido</span>
            <input
              className={inputTextStyles}
              {...register('name', { required: true, maxLength: 50 })}
              type="text"
              placeholder="Escribe tu nombre aquí"
            />
            {errors.name && (
              <span className={errorStyles}>
                Este campo es requerido para continuar
              </span>
            )}
          </label>

          {/* email - Correo electrónico */}
          <label className={labelStyles}>
            <span className={spanStyles}>Correo electrónico</span>
            <input
              className={inputTextStyles}
              {...register('email', { required: true, maxLength: 50 })}
              type="email"
              placeholder="Escribe tu correo electrónico aquí"
            />
            {errors.email && (
              <span className={errorStyles}>
                Este campo es requerido para continuar
              </span>
            )}
          </label>

          {/* type_document - Tipo de documento */}
          <label className={labelStyles}>
            <span className={spanStyles}>Tipo de documento</span>
            <select
              className="block w-full rounded-md border bg-white py-2 px-3 shadow-sm focus:border-[#d1b48f] focus:outline-none focus:ring-[#d1b48f] sm:text-sm"
              {...register('type_document', { required: true })}
              placeholder="s"
            >
              <option value="CC">Cédula de ciudadanía</option>
              <option value="CE">Cédula de extranjería</option>
              <option value="PA">Pasaporte</option>
            </select>
            {errors.type_document && (
              <span className={errorStyles}>
                Este campo es requerido para continuar
              </span>
            )}
          </label>

          {/* document - Número de documento */}
          <label className={labelStyles}>
            <span className={spanStyles}>Numero de Documento</span>
            <input
              className={inputTextStyles}
              {...register('document', { required: true, maxLength: 50 })}
              type="text"
              placeholder="Escribe tu número de documento aqui"
            />
            {errors.document && (
              <span className={errorStyles}>
                Este campo es requerido para continuar
              </span>
            )}
          </label>

          {/* phone - Numero de telefono */}
          <label className={labelStyles}>
            <span className={spanStyles}>Número de teléfono</span>
            <input
              className={inputTextStyles}
              {...register('phone', { required: true, maxLength: 50 })}
              type="text"
              placeholder="Escribe tu número de teléfono aquí"
            />
            {errors.phone && (
              <span className={errorStyles}>
                Este campo es requerido para continuar
              </span>
            )}
          </label>

          {/* policies - Permisos y políticas */}
          <label className={labelStyles + ' col-start-1 col-end-3'}>
            <span className={spanStyles}>Permisos y políticas</span>
            <input
              className="h-4 w-4 rounded text-[#d1b48f] focus:ring-[#d1b48f]"
              {...register('policies', { required: true })}
              type="checkbox"
            />
            <label htmlFor="policies" className="pl-4 text-white">
              Acepto la{' '}
              <a
                href="https://firebasestorage.googleapis.com/v0/b/poderosas-colombia.appspot.com/o/AUTORIZACIO%CC%81N%20PARA%20EL%20TRATAMIENTO%20DE%20DATOS.docx.pdf?alt=media&token=d47ff3cc-e210-443f-9f3c-2b508141bb56"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-[#d1b48f] cursor-pointer">
                  política de tratamiento de datos
                </span>
              </a>
            </label>
            {errors.policies && (
              <span className={errorStyles}>
                Debes aceptar las políticas de tratamiento de datos para
                continuar
              </span>
            )}
          </label>

          <div className="mx-auto py-4 col-start-1 col-end-3">
            <Button text="CONTINUAR" alt="Continuar" btnType="submit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepOne;
