import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Button from '../../../common/button';

// import EducacionPoderosa from '../../../resources/Megáfono3.png';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import './index.css';

const InputPerson = ({
  register = () => {},
  errors = {
    name: false,
    last_name: false,
    type_id: false,
    number_id: false,
    restriccions: false,
  },
  number = 1,
  open = true,
}) => {
  const spanStyles = 'block text-[#d1b48f] text-lg text-white';
  const labelStyles = 'block pb-2';
  const inputTextStyles =
    'block w-full md:min-w-[365px] text-gray rounded-md pl-2 pr-4 focus:border-[#d1b48f] focus:ring-[#d1b48f] sm:text-sm';
  const textAreaStyles =
    'block w-full md:min-w-[746px] text-gray rounded-md pl-2 pr-4 focus:border-[#d1b48f] focus:ring-[#d1b48f] sm:text-sm';
  const errorStyles = 'block pt-2 text-[#e34462] text-sm';

  const a_number = number;
  const containerStyles = 'flex flex-col gap-4 text-black my-4 transition';

  return (
    <div className={containerStyles + (open ? ' active' : '')}>
      <div className="flex flex-col md:flex-row gap-4">
        {/* name - Nombre y apellido */}
        <label className={labelStyles}>
          <span className={spanStyles}>Nombre</span>
          <input
            className={inputTextStyles}
            {...register(`name_${a_number}`, { required: true, maxLength: 50 })}
            type="text"
            placeholder={`Escribe el nombre del asistente #${a_number}`}
          />
          {errors[`name_${a_number}`] && (
            <span className={errorStyles}>
              Este campo es requerido para continuar
            </span>
          )}
        </label>
        {/* last_name - Apellido */}
        <label className={labelStyles}>
          <span className={spanStyles}>Apellido</span>
          <input
            className={inputTextStyles}
            {...register(`last_name_${a_number}`, {
              required: true,
              maxLength: 50,
            })}
            type="text"
            placeholder={`Escribe el apellido del asistente #${a_number}`}
          />
          {errors[`last_name_${a_number}`] && (
            <span className={errorStyles}>
              Este campo es requerido para continuar
            </span>
          )}
        </label>
      </div>

      {/* type_id_ - tipo de identificacion */}
      <div className="flex flex-col md:flex-row gap-4">
        <label className={labelStyles}>
          <span className={spanStyles}>Tipo de identificacion</span>
          <select
            className={inputTextStyles}
            {...register(`type_id_${a_number}`, { required: true })}
            style={{ color: 'GrayText' }}
          >
            <option
              value=""
              disabled
              selected
            >{`Seleccione el tipo de identificación del asistente #${a_number}`}</option>
            <option value="CC" style={{ color: 'GrayText' }}>
              Cédula de ciudadanía
            </option>
            <option value="CE" style={{ color: 'GrayText' }}>
              Cédula de extranjería
            </option>
            <option value="PA" style={{ color: 'GrayText' }}>
              Pasaporte
            </option>
          </select>
          {errors[`type_id_${a_number}`] && (
            <span className={errorStyles}>
              Este campo es requerido para continuar
            </span>
          )}
        </label>

        {/* number_id_ - numbero de identificacion */}
        <label className={labelStyles}>
          <span className={spanStyles}>Numero de identificación</span>
          <input
            className={inputTextStyles}
            {...register(`number_id_${a_number}`, {
              required: true,
              maxLength: 50,
            })}
            type="text"
            placeholder={`Escribe el numero de identificación del asistente #${a_number}`}
            inputMode="numeric" // Add this line
            pattern="[0-9]*" // Add this line
          />
          {errors[`number_id_${a_number}`] && (
            <span className={errorStyles}>
              Este campo es requerido para continuar
            </span>
          )}
        </label>
      </div>

      {/* restricciones - restricciones alimentarias */}
      <div className="md:flex flex-row">
        <label className={labelStyles}>
          <span className={spanStyles}>Restricciones alimentarias</span>
          <textarea
            className={textAreaStyles}
            {...register(`restriccions_${a_number}`, {
              required: true,
              maxLength: 50,
            })}
            type="text"
            placeholder={`Escribe las restricciones alimentarias del asistente #${a_number}`}
          />
          {errors[`restriccions_${a_number}`] && (
            <span className={errorStyles}>
              Este campo es requerido para continuar
            </span>
          )}
        </label>
      </div>
    </div>
  );
};

const StepTwo = ({ handleStep = () => {}, stepTwoData = {} }) => {
  const {
    register,
    handleSubmit,
    // watch,
    // reset,
    formState: { errors },
  } = useForm({
    defaultValues: stepTwoData,
  });
  const [inputs, setInputs] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [open, setOpen] = useState(Array(quantity).fill(true));
  const ticketPrize = 650000; //cambias el valor del ticket

  const onSubmit = (data) => {
    handleStep(3, data);
    // console.log(errors)
  };

  // useEffect(() => {
  //   // console.log(watch().quantity)
  //   // console.log(quantity && quantity !== watch().quantity)
  //   const list = [];
  //   if (quantity !== watch().quantity) {
  //     for (let i = 0; i < watch().quantity - 1; i++) {
  //       // console.log(i)
  //       list.push(
  //         <InputPerson
  //           key={i}
  //           register={register}
  //           errors={errors}
  //           number={i + 1}
  //         />
  //       );
  //     }
  //     setInputs(list);
  //     setQuantity(watch().quantity);
  //     reset({
  //       quantity: watch().quantity,
  //     });
  //   } else {
  //     // setInputs([])
  //     // setQuantity(watch().quantity)
  //   }
  // }, [watch().quantity]);

  // console.log(watch())

  const toggleTicket = (index) => {
    const updatedOpenState = [...open];
    updatedOpenState[index] = !updatedOpenState[index];
    setOpen(updatedOpenState);
  };

  useEffect(() => {
    const generateTicketForms = () => {
      const list = [];
      for (let i = 0; i < quantity; i++) {
        list.push(
          <div key={i} className="py-4 border-b-2 border-[#d1b48f] w-[100%]">
            <div className="flex md:flex justify-between">
              <h3 className="text-xl font-semibold text-[#d1b48f]">
                Información del Ticket #{i + 1}
              </h3>
              <div
                className="w-[24px] h-[24px] cursor-pointer"
                onClick={() => toggleTicket(i)}
              >
                {open[i] ? (
                  <ChevronDownIcon className="w-[24px] h-[24px] text-[#d1b48f]" />
                ) : (
                  <ChevronUpIcon className="w-[24px] h-[24px] text-[#d1b48f]" />
                )}
              </div>
            </div>
            {/* {open[i] && ( */}
            <InputPerson
              register={register}
              errors={errors}
              number={i + 1}
              open={open[i]}
            />
            {/* )} */}
          </div>
        );
      }
      return list;
    };

    setInputs(generateTicketForms());
  }, [quantity, register, errors, open]);

  const totalPrice = quantity * ticketPrize;

  return (
    <div className="h-[100vh] w-[85%] sm:w-full mx-auto grid text-center text-white">
      {/* <img
        className="mx-auto h-[150px] w-auto"
        src={EducacionPoderosa}
        alt="Educacion Poderosa"
      /> */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="py-4 flex flex-col	text-left w-full"
      >
        {/* <h2 className="text-xl font-bold pb-2">Cena poderosa para:</h2> */}
        {/* quantity - Número de personas */}
        <div className="flex items-center gap-2 md:block text-lg">
          <label className="block text-[#d1b48f] text-lg">
            Número de asistentes:
            <select
              {...register('quantity', { required: true })}
              onChange={(e) => setQuantity(parseInt(e.target.value))}
              className="text-gray rounded-md pl-2 pr-4 focus:border-[#d1b48f] focus:ring-[#d1b48f] w-[75px] h-[38px] md:ml-4"
              value={quantity}
              style={{ color: 'GrayText' }}
            >
              {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num} style={{ color: 'GrayText' }}>
                  {num}
                </option>
              ))}
            </select>
          </label>
          {errors.quantity && (
            <span className="block pt-2 text-[#e34462] text-lg">
              Selecciona una opción para continuar
            </span>
          )}
          <div className="flex gap-2">
            <p className="text-[#d1b48f] text-lg font-bold">Precio Total:</p>
            <p className="text-white text-lg font-bold">${totalPrice}</p>
          </div>
        </div>
        {inputs}
        <div className="mx-auto py-4">
          <Button btnType="submit" text="CONTINUAR" alt="Continuar" />
        </div>
        {/* <span className="block text-white text-lg">
          Número de asistentes [colocar aqui el numero de entradas] * X Valor =
          X
        </span>
        acá abajo logica para mostrar un formulario según la cantidad de
        asistentes
        {errors.quantity && (
          <span className="block pt-2 text-[#e34462] text-lg">
            Selecciona una opción para continuar
          </span>
        )}
        <p className="text-white text-lg font-[350] mb-4">Copy</p> */}
      </form>
    </div>
  );
};

export default StepTwo;
