import axios from 'axios';

export const useSaveDonationFunction = async (
  paymentInfo = {
    name: '',
    email: '',
    phone: '',
    type_person: '',
    type_document: '',
    document: '',
    policies: '',
    quantity: '',
    address: '',
    city: '',
    municipality: '',
    day: '',
    attendes: {},
  }
) => {
  try {
    let total = 0;
    const ticketPrize = 650000; //cambias el valor del ticket
    total = ticketPrize * paymentInfo.quantity;
    // if (paymentInfo.quantity === '1') total = 650000;
    // if (paymentInfo.quantity === '2') total = 1200000;
    // if (paymentInfo.quantity === '4') total = 2200000;

    // TODO: iterate attendes
    const resultArray = [];
    const data = paymentInfo.attendes;
    // Iterar sobre el objeto
    for (let i = 1; i <= Number(data.quantity); i++) {
      // Crear un objeto para cada conjunto de datos
      const obj = {
        last_name: data[`last_name_${i}`],
        name: data[`name_${i}`],
        number_id: data[`number_id_${i}`],
        restriccions: data[`restriccions_${i}`],
        type_id: data[`type_id_${i}`],
      };

      // Agregar el objeto al array de resultados
      resultArray.push(obj);
    }

    const payload_db = {
      currency: 'COP',
      amountInCents: Number(total + '00'),
      total: total,
      quantity: paymentInfo.quantity,
      email: paymentInfo.email,
      fullName: paymentInfo.name,
      phoneNumberPrefix: paymentInfo.phoneNumberPrefix || '+57',
      phoneNumber: paymentInfo.phone,
      typePerson: paymentInfo.type_person,
      legalIdType: paymentInfo.type_document,
      legalId: paymentInfo.document,
      policies: new Date().toLocaleString(),
      invitationDay: paymentInfo.day,
      collectCustomerLegalId: 'true',
      addressLine1: paymentInfo.address,
      city: paymentInfo.city,
      region: paymentInfo.municipality,
      country: paymentInfo.country || 'CO',
      publicKey: process.env.REACT_APP_WOMPI_PUBLIC_KEY,
      redirectUrl: process.env.REACT_APP_WOMPI_REDIRECT_URL,
      attendes: resultArray,
      status: 'STARTED',
    };

    if (process.env.REACT_APP_ENV === 'test') payload_db.test = true;

    const res = await axios({
      method: 'POST',
      url: process.env.REACT_APP_FUNCTIONS_URL + '/saveDonationCena2023',
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_FUNCTIONS_TOKEN}`,
      },
      data: payload_db,
    });
    // console.log(res)
    // 2. Payment Payload
    const wompi_payload = {
      reference: res.data.data.id,
      publicKey: payload_db.publicKey,
      redirectUrl: payload_db.redirectUrl,
      currency: payload_db.currency,
      amountInCents: payload_db.amountInCents,
      customerData: {
        email: payload_db.email,
        fullName: payload_db.fullName,
        phoneNumberPrefix: payload_db.phoneNumberPrefix,
        phoneNumber: payload_db.phoneNumber,
        legalIdType: payload_db.legalIdType,
        legalId: payload_db.legalId,
      },
      collectCustomerLegalId: payload_db.collectCustomerLegalId,
      shippingAddress: {
        addressLine1: payload_db.addressLine1,
        city: payload_db.city,
        phoneNumber: payload_db.phoneNumber,
        region: payload_db.region,
        country: payload_db.country,
      },
    };
    // console.log(wompi_payload)
    return wompi_payload;
  } catch (error) {
    // console.log('err usePaymentFunction:', error)
    throw new Error(error);
  }
};
