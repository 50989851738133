// import HomePage from './components/homePage';
import DinnerPage from './components/dinnerPage';

import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Routes>
      {/* <Route path="/" element={<HomePage />}></Route> */}
      <Route path="/" element={<DinnerPage />}></Route>
    </Routes>
  );
}

export default App;
