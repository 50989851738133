// import LogoImage from'../../resources/Logo-Rojo.png';
import bannerlogo from '../../resources/bannerlogo.png';

const Header = () => {
    return <a
    className='cursor-pointer py-4'
    href={process.env.REACT_APP_PODEROSAS_URL}
>

    <img
        className='mx-auto h-[90px]'
        src={bannerlogo}
        alt='Poderosas Logo Red'
    />
</a>
}

export default Header