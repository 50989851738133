const Button = ({
    text = '',
    action = () => {},
    type = 'solid',
    btnType = 'button'
}) => {
    if (type === 'solid')
        return <button
            onClick={action}
            className="
                text-white bg-[#d1b48f] px-4 py-0.5 rounded-lg border-[2px] border-[#d1b48f] uppercase font-bold
                hover:bg-[transparent] hover:text-[#d1b48f]
            "
            type={btnType}
        >
            {text}
        </button>
    else {
        return <button
            onClick={action}
            className="
                text-[#d1b48f] px-4 py-0.5 rounded-lg border-[2px] border-[#d1b48f]uppercase font-bold
                hover:bg-[#d1b48f] hover:text-[white] hover:border-[#d1b48f]
            "
            type={btnType}
        >
            {text}
        </button>
    }
}

export default Button